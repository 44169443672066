export default {
	id: {
		en: 'ID',
		ge: 'ID',
		de: 'ID',
		ru: 'ID',
		cn: 'ID',
		pt: 'ID'
	},
	name: {
		en: 'Name',
		ge: 'სახელი',
		de: 'Name',
		ru: 'Название',
		cn: '名称',
		pt: 'Nome'
	},
	status: {
		en: 'Status',
		ge: 'სტატუსები',
		de: 'Status',
		ru: 'Статус',
		cn: '状态',
		pt: 'Status'
	},
	budget: {
		en: 'Budget',
		ge: 'ბიუჯეტი',
		de: 'Budget',
		ru: 'Бюджет',
		cn: '预算',
		pt: 'Orçamento'
	},
	devices: {
		en: 'Devices',
		ge: 'მოწყობილობები',
		de: 'Geräte',
		ru: 'Устройства',
		cn: '设备',
		pt: 'Dispositivos'
	},
	start: {
		en: 'Start',
		ge: 'დაწყება',
		de: 'Start',
		ru: 'Старт',
		cn: '开始',
		pt: 'Início'
	},
	end: {
		en: 'End',
		ge: 'დასასრული',
		de: 'Ende',
		ru: 'Завершение',
		cn: '结束',
		pt: 'Fim'
	},
	created: {
		en: 'Created',
		ge: 'შექმნილია',
		de: 'Erstellt',
		ru: 'Создано',
		cn: '已创建',
		pt: 'Criado'
	},
	updated: {
		en: 'Updated',
		ge: 'განახლებულია',
		de: 'Aktualisiert',
		ru: 'Изменено',
		cn: '已更新',
		pt: 'Atualizado'
	},
	empty: {
		en: 'There is no requests',
		ge: 'მოთხოვნები არ არი',
		de: 'Keine Anfragen',
		ru: 'Запросы отсутствуют',
		cn: '没有请求',
		pt: 'Não há solicitações'
	},
	requests: {
		en: 'requests',
		ge: 'მოთხოვნები',
		de: 'Anfragen',
		ru: 'запросы',
		cn: /* TODO: to be translated */'requests',
		pt: 'solicitações'
	},
	add_request: {
		en: 'add request',
		ge: 'მოთხოვნის დამატება',
		de: 'Anfrage hinzufügen',
		ru: 'добавить запрос',
		cn: '添加请求',
		pt: 'adicionar solicitação'
	},
	request_status_all_requests: {
		en: 'all requests',
		ge: 'ყველა მოთხოვნა',
		de: 'alle Anfragen',
		ru: 'все запросы',
		cn: '所有请求',
		pt: 'todas as solicitações'
	},
	// draft
	status_change_691: {
		en: 'draft',
		ge: 'მონახაზი',
		de: 'Entwurf',
		ru: 'черновик',
		cn: '草稿',
		pt: 'rascunho'
	},
	// draft
	status_change_691_action: {
		en: 'to draft',
		ge: 'მონახაზი',
		de: 'zum Entwurf',
		ru: 'в черновик',
		cn: '更改为草稿',
		pt: 'para rascunho'
	},
	// awaiting_approval, pending_audit
	status_change_1: {
		en: 'awaiting approval',
		ge: 'ელოდება დამტკიცებას',
		de: 'Warten auf Genehmigung',
		ru: 'ожидание подтверждения',
		cn: '等待审批',
		pt: 'aguardando aprovação'
	},
	// awaiting_approval, pending_audit
	status_change_1_action: {
		en: 'send to approve',
		ge: 'გაგზავნა დასამტკიცებლად',
		de: 'zur Genehmigung senden',
		ru: 'отправить на подтверждение',
		cn: '发送审批请求',
		pt: 'enviar para aprovação'
	},
	// pre_approved
	status_change_2: {
		en: 'pre approved',
		ge: 'წინასწარ დამტკიცებული',
		de: 'vorab genehmigt',
		ru: 'предварительно утверждено',
		cn: /* TODO: to be translated */'pre approved',
		pt: 'pré-aprovado'
	},
	// pre_approved
	status_change_2_action: {
		en: 'pre approve',
		ge: 'წინასწარ დამტკიცება',
		de: 'vorab genehmigen',
		ru: 'предварительно утвердить',
		cn: /* TODO: to be translated */'pre approve',
		pt: 'pré aprovar'
	},
	// approved
	status_change_3: {
		en: 'approved',
		ge: 'დამტკიცებულია',
		de: 'genehmigt',
		ru: 'утверждено',
		cn: '已审批',
		pt: 'aprovado'
	},
	// approved
	status_change_3_action: {
		en: 'approve',
		ge: 'დამტკიცება',
		de: 'genehmigen',
		ru: 'утвердить',
		cn: '审批',
		pt: 'aprovar'
	},
	// rejected, denied
	status_change_4: {
		en: 'rejected',
		ge: 'უარყოფილი',
		de: 'abgelehnt',
		ru: 'отклонено',
		cn: '已驳回',
		pt: 'rejeitado'
	},
	// rejected, denied
	status_change_4_action: {
		en: 'reject',
		ge: 'უარი',
		de: 'ablehnen',
		ru: 'отклонить',
		cn: '驳回',
		pt: 'rejeitar'
	},
	// archived
	status_change_694: {
		en: 'archived',
		ge: 'არქივირებული',
		de: 'archiviert',
		ru: 'в архиве',
		cn: '已存档',
		pt: 'arquivado'
	},
	// archived
	status_change_694_action: {
		en: 'archive',
		ge: 'არქივი',
		de: 'archivieren',
		ru: 'архивировать',
		cn: '存档',
		pt: 'arquivar'
	},
	// deleted
	status_change_695: {
		en: 'deleted',
		ge: 'წაშლილი',
		de: 'gelöscht',
		ru: 'удалено',
		cn: '已删除',
		pt: 'excluído'
	},
	// deleted
	status_change_695_action: {
		en: 'delete',
		ge: 'წაშლა',
		de: 'löschen',
		ru: 'удалить',
		cn: '删除',
		pt: 'excluir'
	},
	lines_per_page: {
		en: 'lines per page',
		ge: 'ხაზები თითო გვერდზე',
		de: 'Zeilen pro Seite',
		ru: 'запросов на страницу',
		cn: '每页行数',
		pt: 'linhas por página'
	},
	imp_ad_plays: {
		en: 'Impressions / Ad Plays',
		ge: 'შთაბეჭდილებები / რეკლამა მუშაობს',
		de: 'Impressionen / Anzeigenschaltungen',
		ru: 'Охват / Показы',
		cn: '展示/广告播放',
		pt: 'Impressões / Reproduções de anúncio'
	},
	dsp_agency: {
		en: 'DSP Agency',
		ge: 'DSP სააგენტო',
		de: 'DSP-Agentur',
		ru: 'Агентство DSP',
		cn: 'DSP Agency',
		pt: 'Agência DSP'
	}
};