<template>
	<main class="layout-full_width" >
		<h2>Dev page</h2>

		<nice-tabs>
			<tab-base-styles data-ni_tab_name="Base Styles" />
			<tab-components data-ni_tab_name="Components" />
			<tab-dev-ui data-ni_tab_name="UI" />
			<tab-dev-ui-2 data-ni_tab_name="UI2" />
			<tab-device-filter data-ni_tab_name="Device filter" />
			<tab-grid data-ni_tab_name="Grid"  />
			<tab-icons data-ni_tab_name="Icons" />
			<tab-icons-2 data-ni_tab_name="Icons 2" />
			<tab-pallete data-ni_tab_name="Color Pallete" />
			<tab-users data-ni_tab_name="Users" />
			<tab-mapbox-geocoder data-ni_tab_name="Mapbox Geocoder" />
		</nice-tabs>
	</main>
</template>

<script>
import NiceTabs from '@/ui/nice-tabs.vue';

import TabBaseStyles from './tab-base-styles';
import TabComponents from './components';
import TabDeviceFilter from './tab-device-filter.vue';
import TabDevUi from './ui';
import TabDevUi2 from './ui-2';
import TabGrid from './tab-grid.vue';
import TabIcons from './tab-icons.vue';
import TabIcons2 from './tab-icons-2';
import TabPallete from './tab-pallete';
import TabUsers from './users';
import TabMapboxGeocoder from './tab-mapbox-geocoder';


export default {
	name: 'PageDevDocs',
	components: {
		NiceTabs,

		TabBaseStyles,
		TabDeviceFilter,
		TabDevUi,
		TabDevUi2,
		TabGrid,
		TabIcons,
		TabIcons2,
		TabComponents,
		TabPallete,
		TabUsers,
		TabMapboxGeocoder,
	},
};
</script>
