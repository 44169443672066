export default {
	ad_plays: {
		en: 'Ad plays',
		ge: 'რეკლამების ჩვენება',
		de: 'Ad Plays',
		ru: 'Ad plays',
		cn: '广告播放',
		pt: 'Ad plays'
	},
	ad_plays_goal: {
		en: 'Number of&nbsp;shows',
		ge: 'რაოდენობა &nbsp;ჩენების',
		de: 'Anzahl der Anzeigenwiedergaben',
		ru: 'Количество показов рекламы',
		cn: '播放次数',
		pt: 'Número de exibições'
	},
	ad_plays_description: {
		en: 'You want to&nbsp;achieve the number of&nbsp;ad&nbsp;plays by&nbsp;the broadcast without audience information.<br /><br />broadcasting points without cameras, without audience data or&nbsp;for audio marketing.',
		ge: 'გსურთ&nbsp;მიაღწიეთ&nbsp;რეკლამის&nbsp;თამაშების რაოდენობას&nbsp;მაუწყებლობის მიერ აუდიტორიის ინფორმაციის გარეშე.<br /><br />მაუწყებლობის წერტილებიში კამერების გარეშე, აუდიტორიის მონაცემების გარეშე ან&nbsp; აუდიო მარკეტინგისთვის.',
		de: 'Sie möchten die Anzahl der Werbeeinblendungen durch die Übertragung ohne Zuschauerinformationen erreichen.<br /><br />Übertragungspunkte ohne Kameras, ohne Zuschauerinformationen oder für Audiomarketing.',
		ru: 'Добиться установленного количества выходов кампании в эфир.<br /><br />Точки без камер, без данных об аудитории или аудиомаркетинг.',
		cn: '您希望在没有受众信息的播放点达成一定次数的广告播放。<br /><br />适用于没有摄像头或受众数据的播放点，以及音频营销。',
		pt: 'Você deseja alcançar o número de reproduções de anúncio através da transmissão sem informações sobre o público.<br /><br />Pontos de transmissão sem câmeras, sem dados de audiência ou para marketing de áudio.'
	},
	cpm: {
		en: 'CPM',
		ge: 'CPM',
		de: 'CPM',
		ru: 'CPM',
		cn: '每千次展示费用',
		pt: 'CPM'
	},
	cpm_goal: {
		en: 'Number of&nbsp;impressions',
		ge: '&nbsp; შთაბეჭდილებების რაოდენობა',
		de: 'Anzahl der Impressionen',
		ru: 'Охват аудитории',
		cn: '展示次数',
		pt: 'Número de impressões'
	},
	cpm_description: {
		en: 'You want to&nbsp;reach your target audience. Broadcasting goesin cycles based on&nbsp;the audience historical data.<br /><br />Suitable for large screens and other massive installations or&nbsp;for audio marketing.',
		ge: 'გსურთ&nbsp;მიაღწიოთ თქვენს სამიზნე აუდიტორიას. მაუწყებლობა მიმდინარეობს ციკლებზე დაყრდნობით&nbsp; რომელიც ეფუძნება აუდიტორიის ისტორიულ მონაცემებს.<br /><br />გამოდგება დიდი ეკრანებისთვის და სხვა მასიური ინსტალაციებისთვის ან&nbsp;აუდიო მარკეტინგისთვის.',
		de: 'Sie möchten Ihre Zielgruppe erreichen. Die Übertragung erfolgt in Zyklen basierend auf den historischen Daten der Zielgruppe.<br /><br />Geeignet für große Bildschirme und andere massive Installationen oder für Audiomarketing.',
		ru: 'Добиться установленного охвата аудитории. Трансляция происходит циклично с учётом исторических данных аудитории.<br /><br />Подходит для больших экранов, массовых инсталляций или аудиомаркетинга.',
		cn: '您希望展示给特定目标受众。根据受众历史数据循环播放。<br /><br />此类型适用于大屏幕或其他大型装置，以及音频营销。',
		pt: 'Você deseja alcançar seu público-alvo. A transmissão ocorre em ciclos com base nos dados históricos da audiência.<br /><br />Adequado para telas grandes e outras instalações de grande porte ou para marketing de áudio.'
	},
	cpv: {
		en: 'CPV',
		ge: 'CPV',
		de: 'CPV',
		ru: 'CPV',
		cn: '每次观看费用',
		pt: 'CPV'
	},
	cpv_goal: {
		en: 'Number of&nbsp;guaranteed views',
		ge: 'რაოდენობა &nbsp;გარანტირებული ნახვების',
		de: 'Anzahl der garantierten Aufrufe',
		ru: 'Просмотры целевой аудитории',
		cn: '确保观看次数',
		pt: 'Número de visualizações garantidas'
	},
	cpv_description: {
		en: 'You want to&nbsp;achieve the number of&nbsp;guaranteed views of&nbsp;the target audience.<br /><br />Suitable for broadcast points with cameras.',
		ge: 'გსურთ&nbsp;მიაღწიოთ&nbsp; სამიზნე აუდიტორიის გარანტირებული ნახვები&nbsp;.<br /><br />გამოიყენება კამერებით გადაცემის წერტილებისთვის.',
		de: 'Sie möchten die Anzahl der garantierten Aufrufe der Zielgruppe erreichen.<br /><br />Geeignet für Übertragungspunkte mit Kameras.',
		ru: 'Достичь установленного количества гарантированных просмотров целевой аудитории.<br /><br />Подходит для точек вещания с камерами.',
		cn: '您希望确保目标受众一定次数的观看。<br /><br />此类型适用于带有摄像头的播放点。',
		pt: 'Você deseja alcançar o número de visualizações garantidas do seu público-alvo.<br /><br />Adequado para pontos de transmissão com câmeras.'
	}
};