export default {
	notification_success: {
		en: 'Changes successful',
		ge: 'ცვლილებები წარმატებულით დასრულდა',
		de: 'Änderungen erfolgreich',
		ru: 'Информация успешно изменена',
		cn: 'Changes successful',
		pt: 'Alterações bem-sucedidas'
	},

	notification_error: {
		en: 'Changes failed',
		ge: 'ცვლილებები ვერ მოხერხდა',
		de: 'Änderungen fehlgeschlagen',
		ru: 'Произошла ошибка',
		cn: 'Changes failed',
		pt: 'Falha nas alterações'
	},

	wrong_email: {
		en: 'The user with such mail does not exist',
		ge: 'ასეთი ელფოსტით მომხმარებელი არ არსებობ',
		de: 'Ein Benutzer mit dieser E-Mail existiert nicht',
		ru: 'Пользователя с такой почтой не существует',
		cn: 'The user with such mail does not exist',
		pt: 'O usuário com esse e-mail não existe'
	},

	wrong_credentials: {
		en: 'Invalid credentials',
		ge: 'არასწორი სერთიფიკატები',
		de: 'Ungültige Anmeldedaten',
		ru: 'Неверные учетные данные',
		cn: 'Invalid credentials',
		pt: 'Credenciais inválidas'
	},

	wrong_code: {
		en: 'Invalid two factor auth code',
		ge: 'არასწორი ავტორიზაციის კოდი',
		de: 'Ungültiger Zwei-Faktor-Authentifizierungscode',
		ru: 'Неверный код авторизации',
		cn: 'Invalid two factor auth code',
		pt: 'Código de autenticação de dois fatores inválido'
	},

	target_limit: {
		en: 'The campaign will end when the goal is completed - {target_limit} ad plays',
		ge: 'კამპანია დასრულდება, როდესაც მიზანს მიაღწევს - {target_limit} რეკლამის ჩართვა',
		de: 'Die Kampagne endet, wenn das Ziel erreicht ist - {target_limit} Anzeigenwiedergaben',
		ru: 'Кампания закончится когда выполнится цель - {target_limit} показов',
		cn: 'The campaign will end when the goal is completed - {target_limit} ad plays',
		pt: 'A campanha terminará quando o objetivo for atingido - {target_limit} reproduções de anúncios'
	}
};
