export default {
	apply: {
		en: 'apply',
		ge: 'მუშაობს',
		de: 'anwenden',
		ru: 'применить',
		cn: '应用',
		pt: 'aplicar'
	},
	audio: {
		en: 'audio',
		ge: 'აუდიო',
		de: 'Audio',
		ru: 'аудио',
		cn: '音频',
		pt: 'áudio'
	},
	'cancel uploading': {
		en: 'cancel uploading',
		ge: 'ატვირთვის გაუქმება',
		de: 'Upload abbrechen',
		ru: 'отменить загрузку',
		cn: '取消上传',
		pt: 'cancelar upload'
	},
	cancel: {
		en: 'cancel',
		ge: 'გაუქმება',
		de: 'abbrechen',
		ru: 'отменить',
		cn: '取消',
		pt: 'cancelar'
	},
	canceled: {
		en: 'canceled',
		ge: 'გაუქმდა',
		de: 'abgebrochen',
		ru: 'отменено',
		cn: '已取消',
		pt: 'cancelado'
	},
	choose: {
		en: 'choose',
		ge: 'არჩევა',
		de: 'wählen',
		ru: 'выберите',
		cn: '选择',
		pt: 'escolher'
	},
	close: {
		en: 'close',
		ge: 'დახურვა',
		de: 'schließen',
		ru: 'закрыть',
		cn: '关闭',
		pt: 'fechar'
	},
	company: {
		en: 'сompany',
		ge: 'კომპანია',
		de: 'Firma',
		ru: 'компания',
		cn: '公司',
		pt: 'empresa'
	},
	'delete': {
		en: 'delete',
		ge: 'წაშლა',
		de: 'löschen',
		ru: 'удалить',
		cn: '删除',
		pt: 'excluir'
	},
	scale: {
		en: 'scale',
		ge: 'მასშტაბი',
		de: 'Skala',
		ru: 'период',
		cn: '缩放',
		pt: 'escala'
	},
	description: {
		en: 'description',
		ge: 'აღწერილობა',
		de: 'Beschreibung',
		ru: 'описание',
		cn: '描述',
		pt: 'descrição'
	},
	duration: {
		en: 'duration',
		ge: 'ხანგრძლივობა',
		de: 'Dauer',
		ru: 'длительность',
		cn: '时长',
		pt: 'duração'
	},
	'edit creative details': {
		en: 'edit creative details',
		ge: 'შეცვალეთ შექმნის დეტალები',
		de: 'kreative Details bearbeiten',
		ru: 'изменить подробности',
		cn: '编辑广告内容详情',
		pt: 'editar detalhes do criativo'
	},
	orientation: {
		en: 'orientation',
		ge: 'ორიენტაცია',
		de: 'Orientierung',
		ru: 'положение',
		cn: '方向',
		pt: 'orientação'
	},
	name: {
		en: 'name',
		ge: 'სახელი',
		de: 'Name',
		ru: 'имя',
		cn: '名称',
		pt: 'nome'
	},
	or: {
		en: 'or',
		ge: 'ან',
		de: 'oder',
		ru: 'или',
		cn: '或',
		pt: 'ou'
	},
	reset: {
		en: 'reset',
		ge: 'გადატვირთვა',
		de: 'zurücksetzen',
		ru: 'сброс',
		cn: '重置',
		pt: 'reiniciar'
	},
	resolution: {
		en: 'resolution',
		ge: 'რეზოლუცია',
		de: 'Auflösung',
		ru: 'разрешение',
		cn: '分辨率',
		pt: 'resolução'
	},
	surface_type: {
		en: 'surface type',
		ge: 'ზედაპირის ტიპი',
		de: 'Oberflächentyp',
		ru: 'тип поверхности',
		cn: '屏幕类型',
		pt: 'tipo de superfície'
	},
	creative: {
		en: 'creative',
		ge: 'შექმნა',
		de: 'kreativ',
		ru: 'креатив',
		cn: '广告内容',
		pt: 'criativo'
	},
	status: {
		en: 'status',
		ge: 'სტატუს',
		de: 'Status',
		ru: 'статус',
		cn: '状态',
		pt: 'status'
	},
	platform: {
		en: 'network',
		ge: 'ქსელი',
		de: 'Netzwerk',
		ru: 'рекламная сеть',
		cn: '网络',
		pt: 'rede'
	},
	audience: {
		en: 'audience',
		ge: 'აუდიტორია',
		de: 'Zielgruppe',
		ru: 'аудитория',
		cn: '受众',
		pt: 'público'
	},
	age: {
		en: 'age',
		ge: 'ასაკი',
		de: 'Alter',
		ru: 'возвраст',
		cn: '年龄',
		pt: 'idade'
	},
	gender: {
		en: 'gender',
		ge: 'სქესი',
		de: 'Geschlecht',
		ru: 'пол',
		cn: '性别',
		pt: 'gênero'
	},
	address: {
		en: 'address',
		ge: 'მისამართი',
		de: 'Adresse',
		ru: 'адрес',
		cn: '地址',
		pt: 'endereço'
	},
	email: {
		en: 'email',
		ge: 'ელფოსტა',
		de: 'E-Mail',
		ru: 'email',
		cn: '电子邮箱',
		pt: 'e-mail'
	},
	email2: {
		en: 'email',
		ge: 'ელფოსტა',
		de: 'E-Mail',
		ru: 'почта',
		cn: '电子邮箱',
		pt: 'e-mail'
	},
	'allow access': {
		en: 'allow access',
		ge: 'წვდომის დაშვება',
		de: 'Zugriff erlauben',
		ru: 'разрешить доступ',
		cn: '允许访问',
		pt: 'permitir acesso'
	},
	back: {
		en: 'back',
		ge: 'უკან',
		de: 'zurück',
		ru: 'назад',
		cn: '返回',
		pt: 'voltar'
	},
	copy: {
		en: 'copy',
		ge: 'ასლი',
		de: 'kopieren',
		ru: 'копировать',
		cn: 'copy', // TODO: translation needed
		pt: 'copiar'
	},
	contract_n: {
		en: 'contract #',
		ge: 'კონტრაქტი	#',
		de: 'Vertrag #',
		ru: 'контракт #',
		cn: '合同编号',
		pt: 'contrato #'
	},
	enter_contract_n: {
		en: 'enter contract number',
		ge: 'შეიყვანეთ ხელშეკრულების ნომერი',
		de: 'Vertragsnummer eingeben',
		ru: 'введите номер контракта',
		cn: '输入合同编号',
		pt: 'digite o número do contrato'
	},
	contract_file: {
		en: 'contract file',
		ge: 'კონტრაქტის ფაილი',
		de: 'Vertragsdatei',
		ru: 'файл контракта',
		cn: '合同文件',
		pt: 'arquivo do contrato'
	},
	creation_date: {
		en: 'creation date',
		ge: 'შექმნის თარიღი',
		de: 'Erstellungsdatum',
		ru: 'дата создания',
		cn: '创建日期',
		pt: 'data de criação'
	},
	'deny access': {
		en: 'deny access',
		ge: 'უარი წვდომაზე',
		de: 'Zugriff verweigern',
		ru: 'запретить доступ',
		cn: '禁止访问',
		pt: 'negar acesso'
	},
	enter_email: {
		en: 'enter email...',
		ge: 'შეიყვანეთ ელფოსტა...',
		de: 'E-Mail eingeben...',
		ru: 'введите email...',
		cn: '输入电子邮箱...',
		pt: 'digite o e-mail...'
	},
	enter_phone: {
		en: 'enter phone...',
		ge: 'შეიყვანეთ ტელეფონის ნომერი...',
		de: 'Telefon eingeben...',
		ru: 'введите телефон...',
		cn: '输入电话...',
		pt: 'digite o telefone...'
	},
	expiration_date: {
		en: 'expiration date',
		ge: 'ვადის გასვლის თარიღი',
		de: 'Ablaufdatum',
		ru: 'дата завешения',
		cn: '过期日期',
		pt: 'data de expiração'
	},
	'has access': {
		en: 'has access',
		ge: 'აქვს წვდომა',
		de: 'hat Zugriff',
		ru: 'есть доступ',
		cn: '拥有访问权限',
		pt: 'tem acesso'
	},
	'no access': {
		en: 'no access',
		ge: 'არ არის წვდომა',
		de: 'kein Zugriff',
		ru: 'нет доступа',
		cn: '没有访问权限',
		pt: 'sem acesso'
	},
	phone: {
		en: 'phone',
		ge: 'ტელეფონი',
		de: 'Telefon',
		ru: 'телефон',
		cn: '电话',
		pt: 'telefone'
	},
	search: {
		en: 'search',
		ge: 'ძებნა',
		de: 'Suche',
		ru: 'поиск',
		cn: '搜索',
		pt: 'pesquisar'
	},
	set_date_ph: {
		en: 'set date',
		ge: 'დაყენებული თარიღი',
		de: 'Datum einstellen',
		ru: 'установить дату',
		cn: '设置日期',
		pt: 'definir data'
	},
	access: {
		en: 'access',
		ge: 'წვდომა',
		de: 'Zugriff',
		ru: 'доступ',
		cn: '访问权限',
		pt: 'acesso'
	},
	available_for_selling: {
		en: 'available for selling',
		ge: 'ხელმისაწვდომია გასაყიდად',
		de: 'zum Verkauf verfügbar',
		ru: 'доступно для продажи',
		cn: '可以销售',
		pt: 'disponível para venda'
	},
	extra_charge: {
		en: 'extra charge to base price',
		ge: 'დამატებითი გადასახადი საბაზისო ფასზე',
		de: 'Aufpreis zum Basispreis',
		ru: 'наценка к базовой стоимости',
		cn: '基准价格之外的额外费用',
		pt: 'cobrança extra sobre o preço base'
	},
	n_a: {
		en: 'n/a',
		ge: 'მიუწვდომელია',
		de: 'nicht verfügbar',
		ru: 'не доступно',
		cn: '不适用',
		pt: 'n/d'
	},
	not_available_for_selling: {
		en: 'not available for selling',
		ge: 'არ არის ხელმისაწვდომი გასაყიდად',
		de: 'nicht zum Verkauf verfügbar',
		ru: 'не доступно для продажи',
		cn: '不可销售',
		pt: 'não disponível para venda'
	},
	points: {
		en: 'points',
		ge: 'პუნქტები',
		de: 'Punkte',
		ru: 'точек',
		cn: '点',
		pt: 'pontos'
	},
	only_mine: {
		en: 'only mine',
		ge: 'მხოლოდ ჩემი',
		de: 'nur meine',
		ru: 'только мое',
		cn: '只看我的',
		pt: 'somente meus'
	},
	selected: {
		en: 'selected',
		ge: 'შერჩეული',
		de: 'ausgewählt',
		ru: 'выбрано',
		cn: '已选择',
		pt: 'selecionado'
	},
	total: {
		en: 'total',
		ge: 'სულ',
		de: 'gesamt',
		ru: 'всего',
		cn: '所有',
		pt: 'total'
	},
	km: {
		en: 'km',
		ge: 'კმ',
		de: 'km',
		ru: 'км',
		cn: '千米',
		pt: 'km'
	},
	kilometers: {
		en: 'km',
		ge: 'კმ',
		de: 'Kilometer',
		ru: 'км',
		cn: '千米',
		pt: 'quilômetros'
	},
	meters: {
		en: 'm',
		ge: 'მ',
		de: 'Meter',
		ru: 'м',
		cn: 'm',  // TODO: translation needed
		pt: 'm'
	},
	add: {
		en: 'add',
		ge: 'დამატება',
		de: 'hinzufügen',
		ru: 'добавить',
		cn: '添加',
		pt: 'adicionar'
	},
	find: {
		en: 'Find...',
		ge: 'იპოვე...',
		de: 'Finden...',
		ru: 'Искать...',
		cn: '查找...',
		pt: 'procurar...'
	},
	select: {
		en: 'Select',
		ge: 'აირჩიე',
		de: 'Wählen',
		ru: 'Выбрать',
		cn: '选择',
		pt: 'selecionar'
	},
	enter_address: {
		en: 'Enter address...',
		ge: 'შეიყვანეთ მისამართი...',
		de: 'Adresse eingeben...',
		ru: 'Введите адрес...',
		cn: '输入地址...',
		pt: 'digite o endereço...'
	},
	impressions: {
		en: 'impressions',
		ge: 'შთაბეჭდილებები',
		de: 'Impressionen',
		ru: 'аудитория',
		cn: '展示',
		pt: 'impressões'
	},
	ad_plays: {
		en: 'ad plays',
		ge: 'რეკლამა ჩართულია',
		de: 'Anzeigenschaltungen',
		ru: 'показы',
		cn: '广告播放',
		pt: 'reproduções de anúncio'
	},
	income: {
		en: 'income',
		ge: 'შემოსავალი',
		de: 'Einnahmen',
		ru: 'прибыль',
		cn: '收入',
		pt: 'receita'
	},
	spent: {
		en: 'spent',
		ge: 'დახარჯული',
		de: 'ausgegeben',
		ru: 'потрачено',
		cn: '已花费',
		pt: 'gasto'
	},
	time_period_placeholder: {
		en: 'Select date interval',
		ge: 'აირჩიე თარიღის ინტერვალი',
		de: 'Datumsintervall auswählen',
		ru: 'Выберите интервал',
		cn: '选择日期间隔',
		pt: 'Selecione o intervalo de datas'
	},
	select_date_placeholder: {
		en: 'Select period',
		ge: 'აირჩიე პერიოდი',
		de: 'Zeitraum auswählen',
		ru: 'Выберите период',
		cn: '选择时间段',
		pt: 'Selecione o período'
	},
	range_type_daily: {
		en: 'Daily',
		ge: 'ყოველდღიური',
		de: 'Täglich',
		ru: 'День',
		cn: '每天',
		pt: 'Diário'
	},
	range_type_weekly: {
		en: 'Weekly',
		ge: 'ყოველკვირეული',
		de: 'Wöchentlich',
		ru: 'Неделя',
		cn: '每周',
		pt: 'Semanal'
	},
	range_type_monthly: {
		en: 'Monthly',
		ge: 'ყოველთვიური',
		de: 'Monatlich',
		ru: 'Месяц',
		cn: '每月',
		pt: 'Mensal'
	},
	page_caption_index: {
		en: 'Dashboard',
		ge: 'სამართავი დაფა',
		de: 'Dashboard',
		ru: 'Обзор',
		cn: '控制面板',
		pt: 'Painel'
	},
	page_caption_login: {
		en: 'Login',
		ge: 'შესვლა',
		de: 'Anmelden',
		ru: 'Логин',
		cn: '登录',
		pt: 'Login'
	},
	'page_caption_report-list': {
		en: 'Reports',
		ge: 'ანგარიშები',
		de: 'Berichte',
		ru: 'Отчеты',
		cn: '报告',
		pt: 'Relatórios'
	},
	page_caption_network: {
		en: 'Network',
		ge: 'ქსელი',
		de: 'Netzwerk',
		ru: 'Сеть',
		cn: '网络',
		pt: 'Rede'
	},
	'page_caption_request-list': {
		en: 'Requests',
		ge: 'მოთხოვნები',
		de: 'Anfragen',
		ru: 'Запросы',
		cn: '请求',
		pt: 'Solicitações'
	},
	'page_caption_request-add': {
		en: 'Add request',
		ge: 'მოთხოვნის დამატება',
		de: 'Anfrage hinzufügen',
		ru: 'Добавить запрос',
		cn: '添加请求',
		pt: 'Adicionar solicitação'
	},
	'page_caption_request-details': {
		en: 'Request details',
		ge: 'მოითხოვეთ დეტალები',
		de: 'Anfragedetails',
		ru: 'Подробности запроса',
		cn: '请求详情',
		pt: 'Detalhes da solicitação'
	},
	'page_caption_campaign-list': {
		en: 'Campaigns',
		ge: 'კამპანიები',
		de: 'Kampagnen',
		ru: 'Кампании',
		cn: '活动',
		pt: 'Campanhas'
	},
	'page_caption_campaign-details': {
		en: 'Campaign details',
		ge: 'კამპანიის დეტალები',
		de: 'Kampagnendetails',
		ru: 'Подробности кампании',
		cn: '活动详情',
		pt: 'Detalhes da campanha'
	},
	'page_caption_whitelabel-settings': {
		en: 'White-label Settings',
		ge: 'თეთრი ეტიკეტის პარამეტრები',
		de: 'White-label-Einstellungen',
		ru: 'Настройки Whitelabel',
		cn: /* TODO: to be translated */  'White-label Settings',
		pt: 'Configurações de marca branca'
	},
	'page_caption_advertiser-list': {
		en: 'Advertisers',
		ge: 'რეკლამის განმთავსებლები',
		de: 'Werbetreibende',
		ru: 'Рекламодатели',
		cn: /* TODO: to be translated */ 'Advertisers',
		pt: 'Anunciantes'
	},
	'page_caption_user-list': {
		en: 'Users',
		ge: 'მომხმარებლები',
		de: 'Benutzer',
		ru: 'Пользователи',
		cn: '用户',
		pt: 'Usuários'
	},
	'page_caption_user-details': {
		en: 'User details',
		ge: 'მომხმარებლის დეტალები',
		de: 'Benutzerdetails',
		ru: 'О пользователе',
		cn: '用户详情',
		pt: 'Detalhes do usuário'
	},
	'page_caption_agency-list': {
		en: 'Agencies',
		ge: 'სააგენტოები',
		de: 'Agenturen',
		ru: 'Агентства',
		cn: '代理商',
		pt: 'Agências'
	},
	'page_caption_agency-create': {
		en: 'New Agency',
		ge: 'ახალი სააგენტო',
		de: 'Neue Agentur',
		ru: 'Новое агентство',
		cn: '新代理商',
		pt: 'Nova agência'
	},
	'page_caption_agency-details': {
		en: 'Agency details',
		ge: 'სააგენტოს დეტალები',
		de: 'Agenturdetails',
		ru: 'Подробности агентства',
		cn: '代理商详情',
		pt: 'Detalhes da agência'
	},
	'page_caption_invitation-user-creation': {
		en: 'Account creation',
		ge: 'ანგარიშის შექმნა',
		de: 'Kontoerstellung',
		ru: 'Создание аккаунта',
		cn: '账号创建',
		pt: 'Criação de conta'
	},
	page_caption_profile: {
		en: 'Profile',
		ge: 'პროფილი',
		de: 'Profil',
		ru: 'Профиль',
		cn: '资料',
		pt: 'Perfil'
	},
	'page_caption_agency-profile': {
		en: 'Agency profile',
		ge: 'სააგენტოს პროფილი',
		de: 'Agenturprofil',
		ru: 'Профиль агентства',
		cn: '代理商资料',
		pt: 'Perfil da agência'
	},
	page_caption_billing: {
		en: 'Billing',
		ge: 'Billing',
		de: 'Abrechnung',
		ru: 'Биллинг',
		cn: /* TODO: translation needed */ 'Billing',
		pt: 'Faturamento'
	},
	'page_caption_document-list': {
		en: 'Docs',
		ge: 'დოკუმენტები',
		de: 'Dokumente',
		ru: 'Документы',
		cn: '文档',
		pt: 'Documentos'
	},
	'page_caption_document-list/document-add': {
		en: 'Add document',
		ge: 'დოკუმენტის დამატება',
		de: 'Dokument hinzufügen',
		ru: 'Добавить документ',
		cn: '添加文档',
		pt: 'Adicionar documento'
	},
	'page_caption_document-list/document-edit': {
		en: 'Edit document',
		ge: 'დოკუმენტის რედაქტირება',
		de: 'Dokument bearbeiten',
		ru: 'Редактировать документ',
		cn: '编辑文档',
		pt: 'Editar documento'
	},
	page_caption_statistics: {
		en: 'Stat',
		ge: 'სტატისტიკა',
		de: 'Statistik',
		ru: 'Статистика',
		cn: '开始',
		pt: 'Estatísticas'
	},
	'page_caption_creative-list': {
		en: 'Creatives',
		ge: 'შექმნილები',
		de: 'Kreative',
		ru: 'Креативы',
		cn: '广告内容',
		pt: 'Criativos'
	},
	page_caption_auth: {
		en: 'Auth 🔐',
		ge: 'Auth 🔐',
		de: 'Auth 🔐',
		ru: 'Auth 🔐',
		cn: '认证 🔐',
		pt: 'Autenticação 🔐'
	},
	'page_caption_error-404': {
		en: '404 🤷🏻\u200d♂️',
		ge: '404 🤷🏻\u200d♂️',
		de: '404 🤷🏻\u200d♂️',
		ru: '404 🤷🏻\u200d♂️',
		cn: '404 🤷🏻\u200d♂️',
		pt: '404 🤷🏻\u200d♂️'
	},
	'page_caption_error-500': {
		en: '500 🙀',
		ge: '500 🙀',
		de: '500 🙀',
		ru: '500 🙀',
		cn: '500 🙀',
		pt: '500 🙀'
	},
	page_caption_dev: {
		en: 'Dev 🛠',
		ge: 'Dev 🛠',
		de: 'Entwickler 🛠',
		ru: 'Работяги 🛠',
		cn: '开发 🛠',
		pt: 'Desenvolvimento 🛠'
	},
	clear_btn: {
		en: 'clear',
		ge: 'გასუფთავება',
		de: 'löschen',
		ru: 'очистить',
		cn: '清除',
		pt: 'limpar'
	},
	apply_btn: {
		en: 'apply',
		ge: 'მუშაობს',
		de: 'anwenden',
		ru: 'применить',
		cn: '应用',
		pt: 'aplicar'
	},
	logo_caption_ssp: {
		en: 'Platform for Programmatic DooH sales',
		ge: 'პლატფორმა პროგრამული DooH გაყიდვებისთვის',
		de: 'Plattform für Programmatic DooH-Verkäufe',
		ru: 'Единая платформа для Программатик продаж в сетях наружной и индор рекламы',
		cn: '程序化户外数字媒体销售平台',
		pt: 'Plataforma para vendas programáticas de DooH'
	},
	logo_caption_dsp: {
		en: 'Platform for Programmatic audience buying in DooH',
		ge: 'პლატფორმა პროგრამული აუდიტორიის ყიდვისთვის DooH-ში',
		de: 'Plattform für den programmgesteuerten Einkauf von Zielgruppen in DooH',
		ru: 'Единая платформа для Программатик закупок аудитории наружной и индор поверхностей',
		cn: '户外数字媒体程序化受众购买平台',
		pt: 'Plataforma para compra programática de audiência em DooH'
	},
	menu_request_add: {
		en: 'Add new request',
		ge: 'ახალი მოთხოვნის დამატება',
		de: 'Neue Anfrage hinzufügen',
		ru: 'Добавить новый запрос',
		cn: 'Add new request',
		pt: 'Adicionar nova solicitação'
	},
	download: {
		en: 'download',
		ge: 'ჩამოტვირთვა',
		de: 'herunterladen',
		ru: 'скачать',
		cn: '下载',
		pt: 'baixar'
	},
	balance: {
		en: 'balance',
		ge: 'ბალანსი',
		de: 'Balance',
		ru: 'баланс',
		cn: '余额',
		pt: 'saldo'
	},
	enter_an_amount_to_add: {
		en: 'Enter an amount to add',
		ge: 'შეიყვანეთ დასამატებელი თანხა',
		de: 'Geben Sie einen Betrag ein',
		ru: 'Введите сумму',
		cn: '输入要添加的金额',
		pt: 'Digite um valor para adicionar'
	},
	failed_to_add_balance: {
		en: 'Failed to add balance',
		ge: 'ბალანსის დამატება ვერ მოხერხდა',
		de: 'Guthaben konnte nicht hinzugefügt werden',
		ru: 'Не удалось добавить деньги',
		cn: '无法添加余额',
		pt: 'Falha ao adicionar saldo'
	},
	failed_to_save: {
		en: 'Failed to save',
		ge: 'Failed to save' /* TODO: to be translated */,
		de: 'Failed to save' /* TODO: to be translated */,
		ru: 'Не удалось сохранить',
		cn: 'Failed to save',
		pt: 'Falha ao salvar'
	},
	// glossary?
	ssp_agency: {
		en: 'SSP Agency',
		ge: 'SSP სააგენტო',
		de: 'SSP-Agentur',
		ru: 'SSP агентство',
		cn: 'SSP Agency',
		pt: 'Agência SSP'
	},
};