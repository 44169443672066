<template>
	<section class="layout-full_width" >
		<invite-user
			:agenciesOptions="agenciesOptions"
			:selectOptions="userSelectOptions"
			:userData="inviteSingleUserData"
		/>
		<agency-card
			:agencyData="singleAgencyData"
		/>
		<user-card
			:user-data="singleUserData"
			rights="ssp"
			:agenciesOptions="agenciesOptions"
			:selectOptions="userSelectOptions"
		/>
		<users-list-table
			:users="agencyUsersList"
		/>
		<platforms-list
			:platforms="platforms"
			:user-id="0"
			rights="admin"
		/>
		<platforms-list
			:platforms="platforms"
			:user-id="0"
			rights="user"
		/>
		<users-list
			title="Агентства"
			:items="agencies"
			add-button-title="Add Agency"
		/>
		<users-list
			title="Пользователи"
			:items="users"
			add-button-title="Invite User"
		/>
	</section>
</template>

<script>
import UsersList from '@/components/users/users-list';
import UserCard from '@/components/users/user-card';
import AgencyCard from '@/components/users/agency-card';
import PlatformsList from '@/components/users/platforms-list';
import UsersListTable from '@/components/users/users-list-table';
import InviteUser from '@/components/users/invite-user';


export default {
	name: 'Users',
	components: {
		UsersList,
		UserCard,
		PlatformsList,
		AgencyCard,
		UsersListTable,
		InviteUser,
	},
	data() {
		return {
			userSelectOptions:{
				langOptions: [
					{ value: 'en', label: 'English', },
				],
				dateOptions: [
					{ value: 'd-m-y', label: 'dd/mm/yyyy', },
				],
				timeOptions: [
					{ value: '24', label: '24 hours time', },
				],
			},
			agencyUsersList:[
				{
					name: 'Конь Педальный',
					email: 'mister@example.com'
				},
				{
					name: 'Ло Шарик',
					email: 'mister@example.com'
				},
				{
					name: 'Ла Ламба',
					email: 'mister@example.com'
				},
			],
			singleAgencyData:{
				address: 'ACME',
				phone: '+7(123)4567890',
				email: 'kre@example.com',
				title: 'ACME',
				site: 'example.com',
				contract: '12313123',
				created: '12-09-2018',
				expiration: '12-09-2022',
			},
			agenciesOptions: [
				{
					value: 1,
					label: 'ACME 1'
				},
				{
					value: 2,
					label: 'ACME 2'
				},
				{
					value: 3,
					label: 'ACME 3'
				},
			],
			platforms:[
				{
					name: 'lil-pumpkin',
					devicesCount: 150,
					fee: '30',
					status: 1,
					access: 1,
				},
				{
					name: 'lil-potato',
					devicesCount: 100,
					fee: '20',
					status: 1,
					access: 0,
				},
				{
					name: 'lil-carrot',
					devicesCount: 50,
					fee: '10',
					status: 0,
					access: 0,
				},
			],
			inviteSingleUserData:{
				name: '',
				surname: '',
				fathersName: '',
				email: '',
				agency: '',
				type: 'dsp',
				options: {
					lang:'en',
					dateFormat: 'd-m-y',
					timeFormat: '24'
				}
			},
			singleUserData:{
				name: 'Fred',
				surname: 'Fredo',
				fathersName: '',
				email: 'frederiko@example.com',
				agency: 1,
				type: 'dsp',
				options:{
					lang:'en',
					dateFormat: 'd-m-y',
					timeFormat: '24'
				}
			},
			agencies: [
				{
					id: 1,
					name: 'ACME 1',
					email: 'acme1@example.com'
				},
				{
					id: 2,
					name: 'ACME 2',
					email: 'acme2@example.com'
				},
				{
					id: 3,
					name: 'ACME 3',
					email: 'acme3@example.com'
				},
			],
			users:[
				{'id': 1, 'name': 'James', 'surname': 'Williams', 'email': 'james@example.com'},
			]
		};
	},
	methods:{

	},
};
</script>

<style lang="sass" scoped >
</style>
