export default {
	create: {
		en: 'Create...',
		ge: 'შექმნა...',
		de: 'Erstellen...',
		ru: 'Создать...',
		cn: 'Create...',
		pt: 'Criar...'
	},

	add_advertiser: {
		en: 'Advertiser',
		ge: 'რეკლამის განმთავსებელი',
		de: 'Werbetreibender',
		ru: 'Рекламодатель',
		cn: 'Advertiser',
		pt: 'Anunciante'
	},

	add_request: {
		en: 'Ad Request',
		ge: 'რეკლამის მოთხოვნა',
		de: 'Werbeanfrage',
		ru: 'Заявка на рекламу',
		cn: 'Ad Request',
		pt: 'Solicitação de anúncio'
	},

	add_balance: {
		en: 'Add Balance',
		ge: 'დაამატე ბალანსი',
		de: 'Guthaben hinzufügen',
		ru: 'Пополнить баланс',
		cn: 'Add Balance',
		pt: 'Adicionar saldo'
	},
};
