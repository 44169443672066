export default {
	all_advertisers: {
		en: 'all advertisers',
		ge: 'ყველა რეკლამის განმთავსებელი',
		de: 'alle Werbetreibenden',
		ru: 'все рекламодатели',
		cn: /* TODO: translation needed */ 'all advertisers',
		pt: 'todos os anunciantes',
	},
	advertiser_column_label: {
		en: 'advertiser name',
		ge: 'რეკლამის სახელი',
		de: 'Name des Werbetreibenden',
		ru: 'рекламодатель',
		cn: /* TODO: translation needed */ 'advertiser',
		pt: 'nome do anunciante',
	},
	status_column_label: {
		en: 'Status',
		ge: 'სტატუსი',
		de: 'Status',
		ru: 'Статус',
		cn: '状态',
		pt: 'Status',
	},
	ssp_agency_column_label: {
		en: 'approved by',
		ge: 'დამტკიცებულია',
		de: 'genehmigt von',
		ru: 'одобрен',
		cn: /* TODO: translation needed */ 'approved by',
		pt: 'aprovado por',
	},
	dsp_agency_column_label: {
		en: 'sent by',
		ge: 'გამოგზავნილი',
		de: 'gesendet von',
		ru: 'добавлен',
		cn: /* TODO: translation needed */ 'sent by',
		pt: 'enviado por',
	},
	phone_number_column_label: {
		en: 'phone number',
		ge: 'ტელეფონის ნომერი',
		de: 'Telefonnummer',
		ru: 'телефон',
		cn: '电话',
		pt: 'número de telefone',
	},
	contact_column_label: {
		en: 'contact details',
		ge: 'კონტაქტის დეტალები',
		de: 'Kontaktdaten',
		ru: 'контактная информация',
		cn: /* TODO: translation needed */ 'contact details',
		pt: 'detalhes de contato',
	},
	email_column_label: {
		en: 'email',
		ge: 'ელფოსტა',
		de: 'E-Mail',
		ru: 'email',
		cn: '电子邮箱',
		pt: 'e-mail',
	},
	created_column_label: {
		en: 'Created',
		ge: 'შექმნილია',
		de: 'Erstellt',
		ru: 'Создано',
		cn: '已创建',
		pt: 'Criado',
	},
	status_change_awaiting_approval: {
		en: 'awaiting approval',
		ge: 'დამტკიცების მოლოდინში',
		de: 'Genehmigung ausstehend',
		ru: 'ожидание подтверждения',
		cn: '正在等待审批',
		pt: 'aguardando aprovação',
	},
	status_change_approved: {
		en: 'approved',
		ge: 'დამტკიცებულია',
		de: 'genehmigt',
		ru: 'подтверждено',
		cn: '已审批',
		pt: 'aprovado',
	},
	add_advertiser: {
		en: 'add advertiser',
		ge: 'რეკლამის დამატება',
		de: 'Werbetreibenden hinzufügen',
		ru: 'добавить рекламодателя',
		cn: /* TODO: translation needed */ 'add advertiser',
		pt: 'adicionar anunciante',
	},
	company_name_label: {
		en: 'advertiser name',
		ge: 'რეკლამის სახელი',
		de: 'Name des Werbetreibenden',
		ru: 'название компании',
		cn: /* TODO: translation needed */ 'advertiser name',
		pt: 'nome do anunciante',
	},
	company_email_label: {
		en: 'email',
		ge: 'ელფოსტა',
		de: 'E-Mail',
		ru: 'email',
		cn: /* TODO: translation needed */ 'email',
		pt: 'e-mail',
	},
	company_phone_label: {
		en: 'phone number',
		ge: 'ტელეფონის ნომერი',
		de: 'Telefonnummer',
		ru: 'номер телефона',
		cn: /* TODO: translation needed */ 'phone number',
		pt: 'número de telefone',
	},
	company_contact_details_label: {
		en: 'contact details',
		ge: 'კონტაქტის დეტალები',
		de: 'Kontaktdaten',
		ru: 'имя контактного лица',
		cn: /* TODO: translation needed */ 'contact details',
		pt: 'detalhes de contato',
	},
	company_website_label: {
		en: 'website link',
		ge: 'ვებგვერდის ბმული',
		de: 'Webseitenlink',
		ru: 'ссылка на сайт',
		cn: /* TODO: translation needed */ 'website link',
		pt: 'link do site',
	},
	failed_to_create_advertiser: {
		en: 'failed to create advertiser',
		ge: 'ვერ შეიქმნა რეკლამის განმთავსებელი',
		de: 'Werbetreibenden konnte nicht erstellt werden',
		ru: 'не удалось создать рекламодателя',
		cn: /* TODO: translation needed */ 'failed to create advertiser',
		pt: 'falha ao criar anunciante',
	},
};
