import _ from 'underscore';
import moment from 'moment';

import { i18n } from '@/i18n.js';

import { send, login, check2fa } from '@/api/request';
import { profile, updateProfile } from '@/api/profile';
import {
	requestAgency,
	requestAgencies,
} from '@/api/agency';
import {
	setterGenerator,
	stateGenerator,
	complexDataSetter,
	capitalize,
} from '@/utilites.js';

import { addRoleRoutes } from '@/router';
import {
	LOCALSTORAGE_LOCALE_NAME,
	DEFAULT_LOCALE,
	LOCALES,
} from '@/constants/locale.js';


export const LOCALSTORAGE_TOKEN_ACCESS_NAME = 'a';
export const LOCALSTORAGE_TOKEN_REFRESH_NAME = 'r';

// TODO: this is a duplicate of what serializer does on the backend
const FIELDS = [
	'id',
	'username',
	'first_name',
	'last_name',
	'father_name',
	'language',
	'date_format',
	'time_format',
	'email',
	'is_email_verified',
	'last_email_change',
	'phone',
	'have_2fa',

	'is_super',
	'roles',
];

const getIsLoggedIn = (state) => Boolean(state.token);


export default {
	namespaced: true,

	state: {
		...stateGenerator(FIELDS),

		// i18n
		availableLocales: Object.keys(LOCALES),
		language: localStorage.getItem(LOCALSTORAGE_LOCALE_NAME) || DEFAULT_LOCALE,

		// config
		token: localStorage.getItem(LOCALSTORAGE_TOKEN_ACCESS_NAME),
		refresh: Object.freeze(localStorage.getItem(LOCALSTORAGE_TOKEN_REFRESH_NAME)), // Переменная не для отслеживания

		// per agency roles + 'super': 'super' - special role
		roles: {},

		availableAgencies: [],
	},

	mutations: {
		// all the fields in FIELDS setters, eg. setId, setUsername...
		...setterGenerator(FIELDS),

		// setData is here
		...complexDataSetter(FIELDS),

		/**
		 * Available Agencies (w/ roles)
		 *
		 * for agency switching
		 */
		setAvailableAgencies(state, agencies) {
			state.availableAgencies = agencies;
		},

		// i18n
		setLanguage(state, locale) {
			state.language = locale;
			i18n.locale = locale;
			if (!locale) {
				localStorage.removeItem(LOCALSTORAGE_LOCALE_NAME);
			}
			else {
				localStorage.setItem(LOCALSTORAGE_LOCALE_NAME, locale);
				moment.locale(locale);
			}
		},

		setAvailableLocales(state, locales) {
			state.availableLocales = locales;
		},

		setToken(state, { token, refresh }={}) {
			state.token = token;

			if (token) {
				localStorage.setItem(LOCALSTORAGE_TOKEN_ACCESS_NAME, token);

				// Если передан ключ refresh то обновляется
				if (refresh && refresh != state.refresh) {
					state.refresh = Object.freeze(refresh);
					localStorage.setItem(LOCALSTORAGE_TOKEN_REFRESH_NAME, refresh);
				}
			} else {
				state.refresh = '';
				localStorage.removeItem(LOCALSTORAGE_TOKEN_ACCESS_NAME);
				localStorage.removeItem(LOCALSTORAGE_TOKEN_REFRESH_NAME);
			}
		},

	},

	getters: {
		/**
		 * @returns {object} Profile info
		 */
		data(state) {
			let result = {};
			for (let key of FIELDS) {
				result[key] = state[key];
			}
			return result;
		},

		/**
		 * @returns {object} Profile info
		 */
		profile(state) {
			let data = _.pick(state, FIELDS);
			data.isLoggedIn = getIsLoggedIn(state);
			return data;
		},

		/**
		 * Auth or not user
		 *
		 * @returns {boolean}
		 */
		isLoggedIn(state) {
			return getIsLoggedIn(state);
		},

		/**
		 * Check if language is set in the localStorage
		 */
		isLanguageSet(state) {
			return localStorage.getItem(LOCALSTORAGE_LOCALE_NAME);
		},

		isEmailVerified(state) {
			return state.is_email_verified;
		},

		fullName(state) {
			return `${state.first_name} ${state.last_name}`.trim();
		},
	},

	actions: {
		/**
		 * Log in
		 */
		async login({ commit, dispatch }, { email, password }) {
			let response = await login(email, password);
			commit('setToken', { token: response.access, refresh: response.refresh });
			return response;
			// dispatch('getProfile');
		},

		/**
		 * Reset password
		 */
		async resetPassword({ commit, dispatch }, { email }) {
			const response = await send('reset-password/', {
				email: email
			}, 'post');

			return response.data;
		},

		/**
		 * Change password
		 */
		async changePassword({ commit, dispatch }, { password, token }) {
			let response = await send('change-password/', {
				password: password,
				token: token
			}, 'post');
			commit('setToken', { token: response.data.access, refresh: response.data.refresh });
			return response;
		},

		async login2fa({ commit, dispatch }, { token, code }) {
			let response = await check2fa(token, code);
			commit('setToken', { token: response.access, refresh: response.refresh });
			return response;
		},

		/**
		 * Request profile data if need
		 *
		 * @param force - (optional) reload required
		 * @param router - (required?) pass vm.$router
		 *
		 * @return {object} Profile data
		 */
		async getProfile({ commit, getters, state, rootState, dispatch }, force) {
			// shortcut - don't reget if already there
			if (state.id && !force) {
				return getters.data;
			}

			const user = await profile();

			// TODO: do we need to set `availableLocales` here?
			commit('setAvailableLocales', user.available_locales);

			const currentAgencyId = rootState.app.agency;
			let currentRole = rootState.app.role;

			// we need to set side here because `requestAgency()` uses it
			// if currentRole isn't in currentAgency.roles - take the first role from the list
			if (user.roles[currentAgencyId].indexOf(currentRole) === -1) {
				currentRole = user.roles[currentAgencyId][0] || 'guest';
			}

			let currentSide = 'guest';
			if (currentRole.startsWith('ssp')) {
				currentSide = 'ssp';
			}
			else if (currentRole.startsWith('dsp')) {
				currentSide = 'dsp';
			}

			// we need role here
			commit('app/setRole', currentRole, { root: true });

			const agency = await requestAgency(currentAgencyId, currentSide);

			const locale = user.language || localStorage.getItem(LOCALSTORAGE_LOCALE_NAME) || DEFAULT_LOCALE;
			if (locale !== user.language) {
				await dispatch('setLanguage', locale);
			}

			commit('setData', user);
			commit('setLanguage', user.language);

			// TODO: hard code + some logic
			// need select agency
			// need select network
			// need to select platform side
			if (currentSide === 'ssp') {
				commit('app/setSspNetworks', agency.ssp_networks, { root: true });
			}
			else if (currentSide === 'dsp') {
				commit('app/setDspNetworks', agency.dsp_networks, { root: true });
			}

			// HACK: we need a fullreload if side is changed
			// TODO: move... where?
			const oldSide = rootState.app.side;

			commit('app/setSide', currentSide, { root: true });

			// TODO: this shit MUST be moved elsewhere
			if (oldSide != currentSide) {
				window.location.reload();
			}

			// MUST be already set
			// commit('app/setAgency', agency.id, { root: true });


			// add appSide routes
			// before setRole to recalculate pagesToShow in the header panel
			// with updated routes list
			if (this.$router && !this.$router.__addedAppSideRoutes) {
				addRoleRoutes(this.$router, currentRole);
			}

			commit('agency/setData', agency, { root: true });
			await dispatch('extra/getPaymentModels', force, { root: true });

			// get available agencies
			commit('setAvailableAgencies', await requestAgencies());

			return getters.data;
		},

		/**
		 * Update profile instance
		 *
		 * @param      {Object}    arg1         The argument 1
		 * @param      {Function}  arg1.commit  The commit
		 * @param      {Object}    arg1.state   The state
		 * @param      {Object}    profileData  The profile data
		 * @return     {Promise}   { description_of_the_return_value }
		 */
		async updateProfile({ commit, state }, profileData) {
			const instance = await updateProfile(profileData);
			const isLanguageChanged = instance.language !== state.language;
			commit('setData', instance);
			if (isLanguageChanged) {
				commit('setLanguage', instance.language);
			}

			return instance;
		},

		// i18n
		async updateLanguage({ commit, state, dispatch }, locale) {
			commit('setLanguage', locale);
			return await updateProfile({ language: locale });
		},

		/**
		 * Clear this store. Used in logout action
		 */
		clear({ commit }) {
			_.each(FIELDS, function(f) {
				commit(`set${capitalize(f, null)}`);
			});
			commit('setToken', {});
		},
	},
};
