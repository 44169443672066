export default {
	two_factor_auth: {
		en: 'Two factor authentication',
		ge: 'ორფაქტორიანი ავთენტიფიკაცია',
		de: 'Zwei-Faktor-Authentifizierung',
		ru: 'Двухфакторная аутентификация',
		cn: /* TODO: to be translated */ 'Two factor auth',
		pt: 'Autenticação de dois fatores'
	},
	deactivate_tfa: {
		en: 'Deactivate two factor auth',
		ge: 'გათიშეთ ორ ფაქტორიანი ავტორიზაცია',
		de: 'Zwei-Faktor-Authentifizierung deaktivieren',
		ru: 'Деактивация двухфакторной аутентификации',
		cn: /* TODO: to be translated */ 'Deactivate two factor auth',
		pt: 'Desativar autenticação de dois fatores'
	},
	confirm_deactivate: {
		en: 'Enter the two factor authorization code to confirm the deactivation',
		ge: 'შეიყვანეთ ორ ფაქტორიანი ავტორიზაციის კოდი დეაქტივაციის დასადასტურებლად',
		de: 'Geben Sie den Zwei-Faktor-Authentifizierungscode ein, um die Deaktivierung zu bestätigen',
		ru: 'Введите код двухфакторной аутентификации для подтверждения деактивации',
		cn: /* TODO: to be translated */ 'Enter the two factor authorization code to confirm the deactivation',
		pt: 'Digite o código de autenticação de dois fatores para confirmar a desativação'
	},
	submit: {
		en: 'Submit',
		ge: 'გაგზავნა',
		de: 'Einreichen',
		ru: 'Подтвердить',
		cn: /* TODO: to be translated */ 'Submit',
		pt: 'Enviar'
	},
	verification_code: {
		en: 'Verification code',
		ge: 'დადასტურების კოდი',
		de: 'Verifizierungscode',
		ru: 'Код верификации',
		cn: /* TODO: to be translated */ 'Verification code',
		pt: 'Código de verificação'
	},
	link_app: {
		en: 'Link your 2FA app',
		ge: 'დააკავშირეთ თქვენი 2FA აპი',
		de: 'Verbinden Sie Ihre 2FA-App',
		ru: 'Привяжите своё приложение двухфакторной аутентификации',
		cn: /* TODO: to be translated */ 'Link your 2FA app',
		pt: 'Vincule seu aplicativo 2FA'
	},
	scan_qr: {
		en: 'Scan the qr-code via your 2FA app, and enter the verification code into the field below to set up the 2-factor auth on your account.',
		ge: 'დაასკანირეთ qr-კოდი თქვენი 2FA აპლიკაციის საშუალებით და შეიყვანეთ დამადასტურებელი კოდი ქვემოთ მოცემულ ველში თქვენს ანგარიშზე 2-ფაქტორიანი ავტორიზაციის დასაყენებლად.',
		de: 'Scannen Sie den QR-Code mit Ihrer 2FA-App und geben Sie den Verifizierungscode in das untenstehende Feld ein, um die Zwei-Faktor-Authentifizierung für Ihr Konto einzurichten.',
		ru: 'Отсканируйте qr-код через приложение для двухфакторной аутентификации и введите проверочный код в поле ниже, чтобы настроить 2-факторную аутентификацию в вашей учетной записи.',
		cn: /* TODO: to be translated */ 'Scan the qr-code via your 2FA app, and enter the verification code into the field below to set up the 2-factor auth on your account.',
		pt: 'Escaneie o QR code com seu aplicativo 2FA e insira o código de verificação no campo abaixo para configurar a autenticação de dois fatores na sua conta'
	},
	backup_codes: {
		en: 'Backup codes',
		ge: 'სარეზერვო კოდები',
		de: 'Sicherungscodes',
		ru: 'Резервные коды',
		cn: /* TODO: to be translated */ 'Backup codes',
		pt: 'Códigos de backup'
	},
	save_codes: {
		en: 'Save these backup codes',
		ge: 'შეინახეთ ეს სარეზერვო კოდები',
		de: 'Speichern Sie diese Sicherungscodes',
		ru: 'Сохраните эти резервные коды',
		cn: /* TODO: to be translated */ 'Save these backup codes',
		pt: 'Salve estes códigos de backup'
	},
	activate: {
		en: 'Activate',
		ge: 'გააქტიურება',
		de: 'Aktivieren',
		ru: 'Активировать',
		cn: /* TODO: to be translated */ 'Activate',
		pt: 'Ativar'
	},
	deactivate: {
		en: 'Deactivate',
		ge: 'გამორთვა',
		de: 'Deaktivieren',
		ru: 'Деактивировать',
		cn: /* TODO: to be translated */ 'Deactivate',
		pt: 'Desativar'
	},
	regenerate_codes: {
		en: 'Regenerate backup codes',
		ge: 'სარეზერვო კოდების რეგენერაცია',
		de: 'Sicherungscodes regenerieren',
		ru: 'Регенерировать резервные коды',
		cn: /* TODO: to be translated */ 'Regenerate backup codes',
		pt: 'Regenerar códigos de backup'
	},
	enter: {
		en: 'Enter',
		ge: 'შესვლა',
		de: 'Eingeben',
		ru: 'Войти',
		cn: /* TODO: to be translated */ 'Enter',
		pt: 'Entrar'
	},
	type_code: {
		en: 'Type verification code',
		ge: 'აკრიფეთ დამადასტურებელი კოდი',
		de: 'Verifizierungscode eingeben',
		ru: 'Ввести код верификации',
		cn: /* TODO: to be translated */ 'Type verification code',
		pt: 'Digite o código de verificação'
	},
	code: {
		en: 'Code',
		ge: 'კოდი',
		de: 'Code',
		ru: 'Код',
		cn: /* TODO: to be translated */ 'Code',
		pt: 'Código'
	},
	next: {
		en: 'Next',
		ge: 'შემდეგი',
		de: 'Weiter',
		ru: 'Далее',
		cn: /* TODO: to be translated */ 'Next',
		pt: 'Próximo'
	},
	change_email: {
		en: 'Confirmation of the email change',
		ge: 'ელფოსტის ცვლილების დადასტურება',
		de: 'Bestätigung der E-Mail-Änderung',
		ru: 'Подтверждение изменения email',
		cn: /* TODO: to be translated */ 'Confirmation of the email change',
		pt: 'Confirmação da mudança de e-mail'
	},
	confirm_chane_email: {
		en: 'Enter the two-factor authentication code to change the email',
		ge: 'შეიყვანეთ ორფაქტორიანი ავთენტიფიკაციის კოდი ელფოსტის შესაცვლელად',
		de: 'Geben Sie den Zwei-Faktor-Authentifizierungscode ein, um die E-Mail zu ändern',
		ru: 'Введите код двухфакторной аутентификации чтобы изменить email',
		cn: /* TODO: to be translated */ 'Enter the two-factor authentication code to change the email',
		pt: 'Digite o código de autenticação de dois fatores para alterar o e-mail'
	},
};