export default {

	no_payment_methods: {
		en: 'No available payment methods',
		ge: 'No available payment methods', /* TODO: to be translated */
		de: 'No available payment methods', /* TODO: to be translated */
		ru: 'Нет доступных способов оплаты',
		cn: /* TODO: to be translated */'No available payment methods',
		pt: 'Nenhum método de pagamento disponível',
	},

	choose_payment_type: {
		en: 'Select payment method',
		ge: 'Select payment method',
		de: 'Select payment method',
		ru: 'Выберите способ оплаты',
		cn: 'Select payment method',
		pt: 'Selecione o método de pagamento',
	},

	bank_card: {
		en: 'Bank card',
		ge: 'საბანკო ბარათი',
		de: 'Bankkarte',
		ru: 'Банковская карта',
		cn: /* TODO: to be translated */'Bank card',
		pt: 'Cartão bancário',
	},

	bank_transfer: {
		en: 'Bank transfer (legal entities only)',
		ge: 'საბანკო გადარიცხვა (მხოლოდ იურიდიული პირები)',
		de: 'Banküberweisung (nur für juristische Personen)',
		ru: 'Безналичный расчет (для юр. лиц)',
		cn: /* TODO: to be translated */'Bank transfer (legal entities only)',
		pt: 'Transferência bancária (apenas para pessoas jurídicas)',
	},

	payment_method_label: {
		en: 'Payment method',
		ge: 'გადახდის მეთოდი',
		de: 'Zahlungsmethode',
		ru: 'Способ оплаты',
		cn: /* TODO: to be translated */'Payment method',
		pt: 'Método de pagamento',
	},

	amount: {
		en: 'Amount',
		ge: 'Amount', /* TODO: to be translated */
		de: 'Amount', /* TODO: to be translated */
		ru: 'Сумма',
		cn: /* TODO: to be translated */'Amount',
		pt: 'Valor',
	},

	amount_excluding_vat_label: {
		en: 'Amount excl. VAT',
		ge: 'თანხის გარდა. დღგ',
		de: 'Betrag exkl. MwSt.',
		ru: 'Сумма без НДС',
		cn: /* TODO: to be translated */'Amount excl. VAT',
		pt: 'Valor sem IVA',
	},

	amount_including_vat_label: {
		en: 'Amount incl. VAT ({tax_rate}%)',
		ge: 'თანხის ჩათვლით. დღგ ({tax_rate}%)', /* TODO: to be translated */
		de: 'Betrag inkl. MwSt. (20%)',
		ru: 'Сумма с НДС ({tax_rate}%)',
		cn: /* TODO: to be translated */'Amount incl. VAT ({tax_rate}%)',
		pt: 'Valor com IVA ({tax_rate}%)',
	},

	payment_name_private_label: {
		en: 'Name',
		ge: 'სახელი',
		de: 'Name',
		ru: 'Имя',
		cn: /* TODO: transition needed */ 'Name',
		pt: 'Nome',
	},

	payment_name_legal_label: {
		en: 'Organization name',
		ge: 'ორგანიზაციის სახელი',
		de: 'Organisationsname',
		ru: 'Наименование организации',
		cn: /* TODO: transition needed */ 'Organization name',
		pt: 'Nome da organização',
	},

	payment_tax_number_label: {
		en: 'TIN',
		ge: 'TIN',
		de: 'Steuernummer',
		ru: 'ИНН',
		cn: /* TODO: transition needed */ 'TIN',
		pt: 'NIF',
	},

	payment_legal_address_label: {
		en: 'Legal address',
		ge: 'იურიდიული მისამართი',
		de: 'Rechtsadresse',
		ru: 'Юридический адрес',
		cn: /* TODO: transition needed */ 'Legal address',
		pt: 'Endereço legal',
	},

	payment_phone_label: {
		en: 'Contact phone',
		ge: 'საკონტაქტო ტელეფონი',
		de: 'Kontakttelefon',
		ru: 'Контактный телефон',
		cn: /* TODO: transition needed */ 'Contact phone',
		pt: 'Telefone de contato',
	},

	add_balance_button: {
		en: 'Add Balance',
		ge: 'დაამატე ბალანსი',
		de: 'Guthaben hinzufügen',
		ru: 'Пополнить баланс',
		cn: /* TODO: to be translated */'Add Balance',
		pt: 'Adicionar Saldo',
	},

	pay: {
		en: 'Pay',
		ge: 'გადახდა',
		de: 'Bezahlen',
		ru: 'Оплатить',
		cn: /* TODO: to be translated */'Pay',
		pt: 'Pagar',
	},

	success_page_title: {
		en: 'Pay is accepted and being processed',
		ge: 'გადახდა მიღებულია და მუშავდება',
		de: 'Zahlung wird akzeptiert und bearbeitet',
		ru: 'Платеж принят и находится в обработке',
		cn: /* TODO: to be translated */'Pay is accepted and being processed',
		pt: 'O pagamento foi aceito e está sendo processado',
	},

	success_page_text: {
		en: 'The balance will be replenish after the payment been processed',
		ge: 'ბალანსი შეივსება გადახდის დამუშავების შემდეგ',
		de: 'Das Guthaben wird nach der Bearbeitung der Zahlung aufgefüllt',
		ru: 'Деньги будут зачислены на баланс после обработки платежа',
		cn: /* TODO: to be translated */'The balance will be replenish after the payment been processed',
		pt: 'O saldo será creditado após o processamento do pagamento',
	},

	fail_page_title: {
		en: 'We failed to process the payment',
		ge: 'ჩვენ ვერ დავამუშავეთ გადახდა',
		de: 'Wir konnten die Zahlung nicht verarbeiten',
		ru: 'Не удалось обработать платеж',
		cn: /* TODO: to be translated */'We failed to process the payment',
		pt: 'Não foi possível processar o pagamento',
	},

	fail_page_text: {
		en: 'Please try again later or contact support',
		ge: 'გთხოვთ სცადოთ მოგვიანებით ან დაუკავშირდით მხარდაჭერის ცენტრს',
		de: 'Bitte versuchen Sie es später erneut oder kontaktieren Sie den Support',
		ru: 'Пожалуйста, попробуйте позже или обратитесь в поддержку',
		cn: /* TODO: to be translated */'Please try again later or contact support',
		pt: 'Por favor, tente novamente mais tarde ou entre em contato com o suporte',
	},
};
