export default {
	booked: {
		en: 'booked',
		ge: 'დაჯავშნილი',
		de: 'gebucht',
		ru: 'забукировать',
		cn: '预订',
		pt: 'reservado'
	},
	proposed: {
		en: 'proposed',
		ge: 'შემოთავაზებული',
		de: 'vorgeschlagen',
		ru: 'предложение',
		cn: '推荐',
		pt: 'proposto'
	},
	booking_level_title: {
		en: 'Booking levels of devices',
		ge: 'მოწყობილობების დაჯავშნის დონეები',
		de: 'Buchungsstufen der Geräte',
		ru: 'Степений занятости устройств',
		cn: '设备预订级别',
		pt: 'Níveis de reserva dos dispositivos'
	},
	select_all: {
		en: 'select all',
		ge: 'აირჩიე ყველა',
		de: 'alles auswählen',
		ru: 'выбрать всё',
		cn: '全选',
		pt: 'selecionar todos'
	},
	select_page: {
		en: 'select page',
		ge: 'აირჩიე გვერდი',
		de: 'Seite auswählen',
		ru: 'выбрать страницу',
		cn: '选择页',
		pt: 'selecionar página'
	},
	id: {
		en: 'ID',
		ge: 'ID',
		de: 'ID',
		ru: 'ID',
		cn: 'ID',
		pt: 'ID'
	},
	name: {
		en: 'name',
		ge: 'სახელი',
		de: 'Name',
		ru: 'название',
		cn: '名称',
		pt: 'nome'
	},
	address: {
		en: 'address',
		ge: 'მისამართი',
		de: 'Adresse',
		ru: 'адрес',
		cn: '地址',
		pt: 'endereço'
	},
	availability: {
		en: 'availability',
		ge: 'ხელმისაწვდომობა',
		de: 'Verfügbarkeit',
		ru: 'доступность',
		cn: '可使用量',
		pt: 'disponibilidade'
	},
	fill_rate: {
		en: 'fill rate',
		ge: 'ჩატვირთვა',
		de: 'Füllrate',
		ru: 'загрузка',
		cn: '供应比率',
		pt: 'taxa de preenchimento'
	},
	impression: {
		en: 'impression',
		ge: 'შთაბეჭდილება',
		de: 'Impression',
		ru: 'аудитория',
		cn: '展示',
		pt: 'impressão'
	},
	resolution: {
		en: 'resolution',
		ge: 'რეზოლუცია',
		de: 'Auflösung',
		ru: 'разрешение',
		cn: '分辨率',
		pt: 'resolução'
	},
	orientation: {
		en: 'orientation',
		ge: 'ორიენტაცია',
		de: 'Orientierung',
		ru: 'ориентация',
		cn: '方向',
		pt: 'orientação'
	},
	status: {
		en: 'status',
		ge: 'სტატუსს',
		de: 'Status',
		ru: 'статус',
		cn: '状态',
		pt: 'status'
	},
	selection: {
		en: 'selection',
		ge: 'არჩევა',
		de: 'Auswahl',
		ru: 'выделено',
		cn: '选择',
		pt: 'seleção'
	},
	budget: {
		en: 'budget',
		ge: 'ბიუჯეტი',
		de: 'Budget',
		ru: 'бюджет',
		cn: '预算',
		pt: 'orçamento'
	},
	screens: {
		en: 'screens',
		ge: 'ეკრანები',
		de: 'Bildschirme',
		ru: 'screens',
		cn: '屏幕',
		pt: 'telas'
	},
	audience: {
		en: 'audience',
		ge: 'აუდიტორია',
		de: 'Zielgruppe',
		ru: 'аудитория',
		cn: '受众',
		pt: 'público'
	},
	cpm: {
		en: 'cpm',
		ge: 'cpm',
		de: 'cpm',
		ru: 'cpm',
		cn: '每千次展示费用',
		pt: 'CPM'
	},
	availability_free: {
		en: 'free',
		ge: 'უფასო',
		de: 'frei',
		ru: 'свободно',
		cn: '空闲',
		pt: 'livre'
	},
	availability_medium: {
		en: 'medium',
		ge: 'საშუალო',
		de: 'mittel',
		ru: 'средняя',
		cn: '中等',
		pt: 'médio'
	},
	availability_busy: {
		en: 'busy',
		ge: 'დაკავებული',
		de: 'beschäftigt',
		ru: 'занято',
		cn: '忙碌',
		pt: 'ocupado'
	},
	on: {
		en: 'on',
		ge: 'ჩართული',
		de: 'an',
		ru: 'вкл.',
		cn: '开',
		pt: 'ligado'
	},
	off: {
		en: 'off',
		ge: 'გამორთული',
		de: 'aus',
		ru: 'выкл.',
		cn: '关',
		pt: 'desligado'
	},
	network_list_title: {
		en: 'Ad Networks',
		ge: 'რეკლამის ქსელები',
		de: 'Ad-Netzwerke',
		ru: 'Рекламные сети',
		cn: '广告网络',
		pt: 'Redes de anúncios'
	},
	device_list_title: {
		en: 'Devices',
		ge: 'მოწყობილობები',
		de: 'Geräte',
		ru: 'Устройства',
		cn: '设备',
		pt: 'Dispositivos'
	},
	map_title: {
		en: 'Device Map',
		ge: 'მოწყობილობის რუკა',
		de: 'Gerätekarte',
		ru: 'Карта устройств',
		cn: '设备图',
		pt: 'Mapa dos dispositivos'
	},
	filter_title: {
		en: 'filter',
		ge: 'ფილტრი',
		de: 'Filter',
		ru: 'фильтр',
		cn: '筛选器',
		pt: 'filtro'
	},
	screen_name: {
		en: 'screen name or id',
		ge: 'screen name  or id',
		de: 'Bildschirmname', /* TODO: to be translated */
		ru: 'название или id экрана',
		cn: '屏幕名称', /* TODO: to be translated */
		pt: 'nome ou ID da tela'
	},
	screen_name_placeholder: {
		en: 'enter screen name...',
		ge: 'შეიყვანეთ ეკრანის სახელი...',
		de: 'Bildschirmname eingeben...',
		ru: 'введите название экрана...',
		cn: '输入屏幕名称...',
		pt: 'digite o nome da tela...'
	},
	hide_screens_caption: {
		en: 'hide not deployed',
		ge: 'დამალეთ ეკრანები, რომლებიც ჯერ არ არის დაკავშირებული',
		de: 'Bildschirme ausblenden, die noch nicht bereitgestellt wurden',
		ru: 'скрыть не подключенные',
		cn: '还未部署隐藏屏幕',
		pt: 'ocultar os não implantados'
	},
	status_label_active: {
		en: 'active',
		ge: 'აქტიური',
		de: 'aktiv',
		ru: 'активна',
		cn: '已启用',
		pt: 'ativo'
	},
	status_label_inactive: {
		en: 'inactive',
		ge: 'არააქტიური',
		de: 'inaktiv',
		ru: 'неактивна',
		cn: '已停用',
		pt: 'inativo'
	},
	status_label_expired: {
		en: 'expired',
		ge: 'ვადაგასული',
		de: 'abgelaufen',
		ru: 'просрочена',
		cn: '已过期',
		pt: 'expirado'
	},
	license_days_left: {
		en: ', {days} left',
		ge: ', {days} დარჩა ',
		de: ', {days} übrig',
		ru: ', {days} осталось',
		cn: '，剩余 {days} 天',
		pt: ', faltam {days} dias'
	},
	download_device_report: {
		en: 'Download report',
		ge: 'Download report',
		de: 'Download report',
		ru: 'Выгрузить отчет',
		cn: 'Download report',
		pt: 'Baixar relatório'
	},
};