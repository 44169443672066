export default {
	transactions: {
		en: 'Transactions',
		ge: 'ტრანზაქცია',
		de: 'Transaktionen',
		ru: 'Транзакции',
		cn: 'Transactions',
		pt: 'Transações'
	},
	id: {
		en: 'ID',
		ge: 'ID',
		de: 'ID',
		ru: 'ID',
		cn: 'ID',
		pt: 'ID'
	},
	debit: {
		en: 'Debit',
		ge: 'დებეტი',
		de: 'Soll',
		ru: 'Дебит',
		cn: 'Debit',
		pt: 'Débito'
	},
	credit: {
		en: 'Credit',
		ge: 'კრედიტი',
		de: 'Haben',
		ru: 'Кредит',
		cn: 'Credit',
		pt: 'Crédito'
	},
	amount: {
		en: 'Amount',
		ge: 'თანხა',
		de: 'Betrag',
		ru: 'Количество средств',
		cn: 'Amount',
		pt: 'Valor'
	},
	correspondent: {
		en: 'Correspondent',
		ge: 'კორესპონდენტი',
		de: 'Korrespondent',
		ru: 'Корреспондент',
		cn: 'Correspondent',
		pt: 'Correspondente'
	},
	campaign_id: {
		en: 'Campaign ID',
		ge: 'კამპანიის ID',
		de: 'Kampagnen-ID',
		ru: 'ID Кампании',
		cn: 'Campaign ID',
		pt: 'ID da campanha'
	},
	description: {
		en: 'Description',
		ge: 'აღწერა',
		de: 'Beschreibung',
		ru: 'Описание',
		cn: 'Description',
		pt: 'Descrição'
	},
	impressions: {
		en: 'Impressions',
		ge: 'შთაბეჭდილებები',
		de: 'Impressionen',
		ru: 'Показы',
		cn: 'Impressions',
		pt: 'Impressões'
	},
	cash: {
		en: 'Cash',
		ge: 'ნაღდი ფული',
		de: 'Bargeld',
		ru: 'Вывод средств',
		cn: 'Cash',
		pt: 'Dinheiro'
	},
	adding_funds: {
		en: 'Adding funds',
		ge: 'ფინანსების დამატება',
		de: 'Geld hinzufügen',
		ru: 'Пополнение',
		cn: 'Adding funds',
		pt: 'Adicionando fundos'
	},
	time: {
		en: 'Time',
		ge: 'დრო',
		de: 'Zeit',
		ru: 'Время',
		cn: 'Time',
		pt: 'Tempo'
	}
};
