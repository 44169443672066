<template>
	<div class="locale-changer">
		<nice-button-2
			v-for="(lang, i) in availableLocales"
			:key="`Lang${i}`"
			:filled="lang === $i18n.locale"
			bordered
			class="locale-changer__button"

			@click="updateLanguage(lang)"
		>
			{{ lang }}
		</nice-button-2>
	</div>
</template>


<script>
import { mapState, mapActions } from 'vuex';

export default {
	name: 'LocaleChanger',

	computed: {
		...mapState('profile', {
			availableLocales: 'availableLocales',
		}),
	},

	methods: {
		...mapActions('profile', ['updateLanguage']),
	},
};
</script>


<style lang="sass" scoped>
.locale-changer
	display: flex
	align-items: center
	// justify-content: center

	& > .locale-changer__button
		margin-right: 15px

		&:last-child
			margin-right: 0
</style>
